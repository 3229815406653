/*
Theme Name: Drupal 7 Gulp Starter Theme
Theme URI: https://github.com/lasseyls/Drupal-7-Starter-Theme
Description: Drupal 7 Gulp Starter Theme for use as a starting template for building custom themes.
Author: Lasse Moos
Author URI: http://www.yourlocalstudio.dk
License: GNU General Public License v2.0
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Version: 1.0
*/

// Reset, Variables, Mixins, Etc
// DO NOT EDIT ORDER OF FILES
@import "partials/mixins";
@import "partials/variables";
@import "partials/normalize";

// Globals
@import "partials/global";

// The grid
@import "partials/grid";

// Page and Site Styles
@import "partials/page";
@import "partials/posts";
@import "partials/blocks";
@import "partials/search";
@import "partials/forms";

// Helper Classes, Print, Etc
@import "partials/helpers";
@import "partials/print";
@import "partials/menu";

#header {
    background:#e0e0d8;
    min-height:60px;
}
#main {
    margin: 40px;
}
.page-kaart #main {
    margin: 0;
}
.front #main {
    margin: 0;
}

.page-heatmap #main {
    margin: 0;
}

.cbp-spmenu {

    a {
        text-decoration: none;
    }
}

.pane-system-main-menu {
    position: absolute;
    }

.cbp-spmenu-vertical {
    z-index: 9999;
}
.cbp-spmenu-push {
    overflow-x:visible;
}

i.icon {
    padding-right: 1em;
}

#showLeft {
    position: absolute;
    font-size: 1.9em;
    padding: 20px;
    right:0;
    top:0;
    background:none;
    border:none;
    color: #afdefa;
    &:hover {
    color:white;
}
    i {
        padding-right: 0;
    }
}

#showLeft2 {
        font-size: 1.9em;
        @media all and (max-width:600px) {
            font-size: 1em;
        }
        position: relative;
        border:1px solid #0074BD;
        border-radius: 8px;
        padding: 5px 10px;
        top:10px;
        left:10px;
        text-transform: uppercase;
        transition: all 0.4s ease;

        &:hover {
            color:white;
            background: #0074BD;
            text-decoration: none;
        }



}

.page-home #main {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#header h2  {
    width:100%;
    text-align:center;
    padding: 0;
    margin: 0;
    padding-top: 20px;
    font-size: 1.8em;
    text-transform: uppercase;
    @media all and (max-width:650px) {
        font-size: 1.4em;
        text-align:right;
        padding-right: 20px;
    }
        @media all and (max-width:400px) {
        font-size: 1em;

    }
}

h1 {
    text-transform: uppercase;
}

.view-articles,
.view-veldbezoek {
    table {
        font-size: 0.85em;
    }
    .views-field-field-datum {
        min-width: 9em;
    }
    .views-field-field-groepsgrootte-mensen,
    .views-field-field-groepsgrootte-wisenten {
        max-width:8em;
    }
    .views-field-field-gedrag-mens {
        min-width:10em;
    }
    .views-field-field-notities {
        width:20%;
    }
}

fieldset {
border: 1px solid #ccc;
padding: 2.5em 0 0 0;
/* LTR */
position: relative;
margin: 1em 0;
}

input.form-autocomplete, input.form-text, input.form-file, textarea.form-textarea, select.form-select {
    max-width: 100%;
    width:100%;
}

.view-veldbezoek,
.view-documenten,
.view-articles {
    .view-content {
    display:block;
    overflow:scroll;
    max-width:100%;
}
}

form.node-logboek_entree-form #main{
    max-width:800px;
    @media all and (min-width:840px) {
        margin:40px auto;
    }
}

.page-node-edit,
.page-node-add {
form.node-logboek_entree-form {
 .panel-2col-stacked .panel-col-last,
 .panel-2col-stacked .panel-col-last {
    float: none;
    width: 100%;
}
 .panel-2col-stacked .panel-col-first,
 .panel-2col-stacked .panel-col-first{
    float: none;
    width: 100%;
}
}
}

.node-type-logboek-entree,
.page-node-add-logboek-entree {

     .description {
    color:red !important;
}
 fieldset .description {
    color:inherit !important;
}
fieldset {
    border: none;
    margin: 0;
    padding: 0;
    .fieldset-wrapper {
        @include microclearfix ();
        padding: 0;
    }
    .fieldset-legend {
        position: relative;
        padding: 0;
        margin: 0;
        text-transform: none;
            font-size: 1.2em;
    margin-bottom: 1em;
    }
}
.form-item {

     label {
    font-size: 1.2em;
    margin-bottom: 1em;
}
    .form-type-date-select label {
        font-size: inherit;
        margin-bottom: 0;
        margin-top: 0;
    }

}
    fieldset .form-item label {
        font-size: inherit;
        margin-bottom: 0;
        margin-top: 0;
    }
h2.pane-title {
    font-weight: 600;
}
.field-type-number-integer input {
    width:100px;
}

}

.form-item-field-time-und-0-value input,
.form-item-field-time-und-0-value2 input {
    width:80px;

}
.form-item-field-time-und-0-value,
.form-item-field-time-und-0-value2{
    float:left;
    padding-right: 30px;
}

#ui-timepicker-div {
    *,
:before,
:after {
    box-sizing: content-box;
}

}

.tabs {
    @include microclearfix();
    border-bottom: 1px solid #a6a7a2;;
}

.view-nieuw-logboek table td {
    vertical-align: top;
}



